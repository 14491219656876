import { graphql, Script } from 'gatsby'
import { css } from '@emotion/react'
import { media } from '@dfds-ui/theme'
import { Headline } from '@dfds-ui/react-components'
import { FlexBox } from '@dfds-ui/react-components/flexbox'

import { FlexCard } from '../Card'
import { gridMap } from '../../utils'
import { Markdown } from '../Markdown'

const ExternalWidget = ({ sys, title, text, widgetSource }) => {
  return (
    <FlexCard
      width={gridMap['full-width']}
      mediaWidth={{ md: 12 }}
      css={css`
        flex-direction: column-reverse;

        ${media.greaterThan('l')`
          flex-direction: column;
        `}
      `}
      media={
        <FlexBox justifyCenter={true} itemsCenter={true}>
          <div
            id={sys.id}
            css={css`
              display: block;
              width: 100%;
            `}
          />
        </FlexBox>
      }
      reverse
    >
      <Headline as={'h2'}>{title}</Headline>
      <Markdown source={text} />
      <Script
        id={sys.id}
        src={`${widgetSource}?widgetIdentification=${sys.id}`}
        off-main-thread
      />
    </FlexCard>
  )
}

export const ExternalWidgetFragment = graphql`
  fragment ExternalWidget on contentful_ExternalWidget {
    sys {
      id
    }
    title
    text
    widgetSource
  }
`

export default ExternalWidget
